<template>
  <v-list-item dense>
    <v-list-item-avatar>
      <v-avatar size="25" color="primary">
        <v-icon small dark>mdi-text-box-check</v-icon>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <a @click="disabled ? null : $emit('clickAction', examen)" class="link"
          >{{ examen.nombre }}
        </a>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="mostrarEliminacion && !vistaSeguimiento">
      <v-btn
        icon
        small
        color="primary"
        :disabled="disabled"
        @click="$emit('borrar', examen)"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    examen: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    mostrarEliminacion: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
  }
};
</script>
