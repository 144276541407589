<template>
  <v-dialog v-model="mostrar" persistent width="500">
    <v-card>
      <v-toolbar dense flat dark tile color="primary">
        <v-toolbar-title>Seleccionar examen para aplicar</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-card
          v-for="examen in examenes"
          :key="examen._id"
          color="#eceff1"
          flat
          tile
        >
          <examen-item
            :examen="examen"
            @clickAction="examenSeleccionado"
          ></examen-item>
        </v-card>
        <div class="d-flex justify-end pt-2">
          <v-btn color="secondary" outlined small @click="$emit('cancelar')">
            <span>Cancelar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import examenItem from "./examenItem.vue";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    examenes: { type: Array, default: () => [] },
  },

  components: {
    "examen-item": examenItem,
  },

  methods: {
    examenSeleccionado(examen) {
      this.$emit("examenSeleccionado", examen);
    },
  },
};
</script>
