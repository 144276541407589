import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{attrs:{"dense":""}},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"size":"25","color":"primary"}},[_c(VIcon,{attrs:{"small":"","dark":""}},[_vm._v("mdi-text-box-check")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',{staticClass:"link",on:{"click":function($event){_vm.disabled ? null : _vm.$emit('clickAction', _vm.examen)}}},[_vm._v(_vm._s(_vm.examen.nombre)+" ")])])],1),(_vm.mostrarEliminacion && !_vm.vistaSeguimiento)?_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","small":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('borrar', _vm.examen)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }